<template>
	<div class="mt-1">
		<v-row>
			<v-col cols="6">	
				<v-text-field
					label="Account Name"
					hide-details="auto"
					v-model="salesProspectingForm.account_name"
					outlined
					dense
					:readonly="disabled"
					:rules="genericRule"
				></v-text-field>
			</v-col>
		</v-row>
		
		<v-row>
			<v-col cols="6">
				<v-text-field
					label="Legal Entity Name"
					hide-details="auto"
					v-model="salesProspectingForm.legal_entity_name"
					outlined
					dense
					:readonly="disabled"
					:rules="genericRule"
				></v-text-field>
			</v-col>
		</v-row>
						
		<v-row>
			<v-col cols="6">
				<v-text-field
					label="Trade Name"
					hide-details="auto"
					v-model="salesProspectingForm.trade_name"
					outlined
					dense
					:readonly="disabled"
					:rules="genericRule"
				></v-text-field>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="6">
				<v-text-field
					label="BP ID Number"
					hide-details="auto"
					type="number"
					hide-spin-buttons
					v-model="salesProspectingForm.bp_idno"
					outlined
					dense
					:readonly="disabled"
				></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	props: ['disabled'],
    computed: {
		...mapGetters({
            salesProspectingForm: 'salesProspecting/salesProspectingForm',
			genericRule: 'salesProspecting/genericRule'
		}),
	}
};
</script>
